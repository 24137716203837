var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ЧПУ URL:","label-for":"slug"}},[_c('ValidationProvider',{attrs:{"name":"slug","vid":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","autocomplete":"off","placeholder":"Введите ЧПУ URL"},model:{value:(_vm.citiesData.slug),callback:function ($$v) {_vm.$set(_vm.citiesData, "slug", $$v)},expression:"citiesData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Название:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Введите название"},model:{value:(_vm.citiesData.name),callback:function ($$v) {_vm.$set(_vm.citiesData, "name", $$v)},expression:"citiesData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Р.П. Название:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"rp_name","vid":"rp_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rp_name","autocomplete":"off","placeholder":"Введите название в Родительном падеже"},model:{value:(_vm.citiesData.rp_name),callback:function ($$v) {_vm.$set(_vm.citiesData, "rp_name", $$v)},expression:"citiesData.rp_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"П.П. Название:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"pp_name","vid":"pp_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rp_name","autocomplete":"off","placeholder":"Введите название в Предложном падеже"},model:{value:(_vm.citiesData.pp_name),callback:function ($$v) {_vm.$set(_vm.citiesData, "pp_name", $$v)},expression:"citiesData.pp_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Регион:","label-for":"region_id"}},[_c('ValidationProvider',{attrs:{"name":"region_id","vid":"region_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.regionsList},model:{value:(_vm.citiesData.region_id),callback:function ($$v) {_vm.$set(_vm.citiesData, "region_id", $$v)},expression:"citiesData.region_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Slug DOCDOC:","label-for":"docdoc_slug"}},[_c('ValidationProvider',{attrs:{"name":"docdoc_slug","vid":"docdoc_slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"docdoc_slug","autocomplete":"off","placeholder":"Введите Slug с DOCDOC"},model:{value:(_vm.citiesData.docdoc_slug),callback:function ($$v) {_vm.$set(_vm.citiesData, "docdoc_slug", $$v)},expression:"citiesData.docdoc_slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.citiesData.id ? 'Обновить и закрыть' : 'Создать и закрыть')+" ")])],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }