<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver ref="form">
        <Form
          :cities-data.sync="formData"
          :regions-list="regionsList"
          @formSubmit="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { FormDataConverter } from '@/mixins/formData'
import Form from './Form.vue'

const emptyFormData = {
  id: null,
  slug: '',
  name: '',
  rp_name: '',
  pp_name: '',
  region_id: null,
  docdoc_slug: '',
}

export default {
  name: 'CitiesCreate',
  components: {
    BCard,
    BOverlay,
    Form,
  },
  metaInfo: {
    title: 'Создание города',
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      serverErrors: {},
      regionsList: [],
    }
  },
  mounted() {
    Promise.all([
      this.doLoadRegions(),
    ]).then(() => {
      this.loading = false
    })
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave(callbackData) {
      this.loading = true

      const prepareFormData = _.merge(this.formData, { textEditorAttachments: callbackData.textEditorAttachments })
      const formData = new FormDataConverter(prepareFormData)

      try {
        const res = await axiosIns({
          method: 'POST',
          url: 'cities/create',
          data: formData.getData(),
        })
        this.loading = false
        if (res.data.status === 'success') {
          await this.$router.push({ name: 'cities' })
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Город создан, теперь он доступен для редактирования',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Не все поля заполнены!',
            variant: 'danger',
          })
        }
      }
    },
    doLoadRegions() {
      const req = axiosIns({
        method: 'GET',
        url: '/regions/options',
      })

      req.then(response => {
        this.regionsList = response.data.data
      })

      return req
    },
  },
}
</script>
