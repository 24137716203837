<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="ЧПУ URL:"
          label-for="slug"
        >
          <ValidationProvider
            #default="{ errors }"
            name="slug"
            vid="slug"
          >
            <b-form-input
              id="slug"
              v-model="citiesData.slug"
              autocomplete="off"
              placeholder="Введите ЧПУ URL"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Название:"
          label-for="name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
          >
            <b-form-input
              id="name"
              v-model="citiesData.name"
              autocomplete="off"
              placeholder="Введите название"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Р.П. Название:"
          label-for="name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rp_name"
            vid="rp_name"
          >
            <b-form-input
              id="rp_name"
              v-model="citiesData.rp_name"
              autocomplete="off"
              placeholder="Введите название в Родительном падеже"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="П.П. Название:"
          label-for="name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="pp_name"
            vid="pp_name"
          >
            <b-form-input
              id="rp_name"
              v-model="citiesData.pp_name"
              autocomplete="off"
              placeholder="Введите название в Предложном падеже"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Регион:"
          label-for="region_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="region_id"
            vid="region_id"
          >
            <v-select
              v-model="citiesData.region_id"
              label="name"
              :reduce="option => option.id"
              :options="regionsList"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Slug DOCDOC:"
          label-for="docdoc_slug"
        >
          <ValidationProvider
            #default="{ errors }"
            name="docdoc_slug"
            vid="docdoc_slug"
          >
            <b-form-input
              id="docdoc_slug"
              v-model="citiesData.docdoc_slug"
              autocomplete="off"
              placeholder="Введите Slug с DOCDOC"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmit"
        >
          {{ citiesData.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  name: 'CitiesForm',
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  props: {
    citiesData: {
      type: Object,
      require: true,
      default: () => {},
    },
    regionsList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
